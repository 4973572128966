import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"


const ServiceSidebar = (props) => {
    
    return (
        <div className="services-details-info">
            <ul className="services-list">
                {props.servicesList && props.servicesList.map((sc, i) => (
                    <li key={`service-list-${i}`}>
                        <Link to={sc.node.uri} className={props.currentPage === sc.node.uri ? "active" : ""}>
                            {sc.node.title}
                        </Link>
                    </li>
                ))}
            </ul>

            {/* { props.details.fileDetails && (
                <div className="download-file">
                    <h3>{props.details.fileLabel}</h3>
                    <ul>
                        {props.details.fileDetails.map((dt, i) => (
                            <li key={`sservice-file-${i}`}>
                                <a href={dt.group.file.mediaItemUrl} download rel="noopener noreferrer" target="_blank">
                                    {dt.group.label} <i className='bx bxs-file-pdf'></i>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            { props.details.infoDetails && (
                <div className="services-contact-info">
                    <h3>{props.details.infoLabel}</h3>
                    <ul>
                        {props.details.infoDetails.map((dt, i) => (
                            <li key={`sservice-contact-${i}`}>
                                <div style={{ position: "absolute", left: "0" }}>
                                    <Img fixed={dt.clientDetails.image.imageFile.childImageSharp.fixed} alt={dt.clientDetails.image.altText} style={{ top: "4px" }} />
                                </div>
                                <span>{dt.clientDetails.label}</span>
                                <div dangerouslySetInnerHTML={{__html: dt.clientDetails.content }}></div>
                            </li>
                        ))}
                    </ul>
                </div>
            )} */}

        </div>
    )
}

export default ServiceSidebar