import React, {useEffect} from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContent from '../components/ServiceDetails/ServiceDetailsContent'
// import InterestForm from '../components/Contact/InterestForm'
import Faqs from '../components/ServiceDetails/Faqs'

import ContactForm from '../components/Contact/ContactForm'


const ServiceTemplate = (props) => {
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    useEffect(() => {
        let element = document.getElementsByClassName("scroll-to-form");
        if (element && element[0]) {
            element[0].addEventListener('click', scrollToForm, false)
        }
    }, [])

    function scrollToForm() {
        let element = document.getElementById("contact-form-s");
        if (element) {
            console.log(element)
            setTimeout(() => {
                element.scrollIntoView({ behavior: "smooth" });
            }, [500])
        }
    }


    return (
        <Layout metaData={constructMetaData(data.service, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu}  lang={props.pageContext.language} />
            <PageBanner
                pageTitle={data.service.title}
                homePageText={props.pageContext.language ==="EN" ? "Home" : (props.pageContext.language ==="FR" ? "Accueil" : "Αρχική")}
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")}
                prevPageText="Web Solutions"
                prevPageUrl={props.pageContext.language ==="EN" ? "/en/web-solutions/" : "/web-solutions/"}
                activePageText={data.service.servicesAcf.titleExcerpt}
            />
            <ServiceDetailsContent
                service={data.service}
                servicesList={data.services.edges}
                currentPage={props.pageContext.currentPage}
            />
            {/* <InterestForm
                iForm={data.interestTemplateUri.freeAnalysisTemplateAcf}
            /> */}

            { (data.service.servicesAcf.faqs && data.service.servicesAcf.faqs.faqs && data.service.servicesAcf.faqs.faqs.length > 0) && (
                <Faqs
                    data={data.service.servicesAcf.faqs}
                />
            )}

            <div id="contact-form-s" style={{ paddingTop: "3rem" }}>
                <ContactForm
                    data={data?.template?.contactInformationTemplate}
                    serviceData={data.service.servicesAcf.cgroup}
                />
            </div>

            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    );
}

export default ServiceTemplate

export const pageQuery = graphql`
    query GET_SINGLE_SERVICE($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum, $interestTemplateUri: ID!, $footerTemplateUri: ID!, $contactTemplateUri: ID! ) {
        wpgraphql {
            service(id: $id){
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
                serviceCategories{
                    edges{
                        node{
                            breadcrumbServicesAcf{
                                breadcrumbName
                                breadcrumbSlug
                            }
                        }
                    }
                }
                title
                content
                uri
                servicesAcf{
                    titleExcerpt
                    featuredImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 790, quality: 100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    infoLabel
                    infoDetails{
                        clientDetails{
                            label
                            content
                            image{
                                sourceUrl
                                altText
                                imageFile{
                                    childImageSharp{
                                        fixed(width: 350 , height: 386){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                        }
                    }
                    fileLabel
                    fileDetails{
                        group{
                            label
                            file{
                                mediaItemUrl
                            }
                        }
                    }
                    faqs{
                        title
                        faqs {
                            question
                            answer
                            visibleInSite
                        }
                    }
                    cgroup{
                        title
                        subtitle
                        description
                    }
                }
            }
            services (first: 200, where: {language: $language , orderby: {field: MENU_ORDER, order: ASC}}) {
                edges {
                    node {
                        id
                        uri
                        title
                        slug
                    }
                }
            }

            interestTemplateUri: template(id: $interestTemplateUri, idType: SLUG) {
                ...InterestFormFragment
            }
            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }

            template(id: $contactTemplateUri, idType: SLUG) {
                contactInformationTemplate {
                    form {
                        content
                        title
                        subTitle
                        content
                        fullName
                        email{
                            label
                            errorMessage
                        }
                        subject
                        message{
                            label
                            errorMessage
                        }
                        telephone{
                            label
                            errorMessage
                        }
                        sendButton
                    }
                    responseMessages{
                        success
                        failed
                    }
                    image {
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp {
                                fluid(maxWidth: 585, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`