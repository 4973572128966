import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import Img from "gatsby-image"

// import details1 from '../../assets/images/services/services-details1.jpg'
// import project2 from '../../assets/images/projects/project2.jpg'
// import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = (props) => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <Img 
                                fluid={props.service.servicesAcf.featuredImage.imageFile.childImageSharp.fluid} 
                                alt={props.service.servicesAcf.featuredImage.altText} 
                                style={{objectFit: "cover",width: "100%",height: "100%"}}
                                className="bg-position" 
                            />

                            {/* <picture>
                                <source type="image/webp" srcSet={ props.service.servicesAcf.featuredImage.imageFile.childImageSharp.fluid.srcSetWebp } importance="high" />
                                <source type="image/jpg" srcSet={  props.service.servicesAcf.featuredImage.imageFile.childImageSharp.fluid.srcSetWebp} />
                                <img
                                    alt={props.service.servicesAcf.featuredImage.altText}
                                    src={props.service.servicesAcf.featuredImage.imageFile.childImageSharp.fluid.src}
                                    importance="high"
                                    style={{objectFit: "cover",width: "100%",height: "100%"}}
                                    className="bg-position"
                                />
                            </picture> */}
                        </div>

                        <div className="services-details-desc" dangerouslySetInnerHTML={{__html: props.service.content}}></div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar 
                            details={props.service.servicesAcf}
                            servicesList={ props.servicesList }
                            currentPage={ props.currentPage }
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent